<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <!-- <h2 class="brand-text text-primary ml-1">
        Vuexy
      </h2> -->
    </b-link>

    <div class="container mt-5 pt-3 mx-md-5">
      <b-overlay
        :show="showOverlay"
        rounded="sm"
        variant="dark"
      >
        <div>
          <!--  <b-card> -->
          <!-- form -->
          <!-- <b-form @submit.prevent> -->
          <div class="w-100 text-center">
            <b-row>
              <b-col cols="12">
                <h2 class="mb-1">
                  Request A Dispatch 🚀
                </h2>
                <p class="mb-3">
                  1-Click to the nearest delivery service companies, riders,
                  drivers to handle your delivery request!
                </p>
              </b-col>

              <b-col
                v-if="
                  daas.status == 'REQUEST' ||
                    daas.status == 'PAID' ||
                    daas.status == 'COLLECTED' ||
                    daas.status == 'DELIVERED' ||
                    daas.status == 'DISPATCH'
                "
                cols="12 text-left"
              >
                <div class="alert alert-info">
                  <h4 class="alert-heading">
                    Info:
                  </h4>
                  <div class="alert-body">
                    <span v-if="daas.status == 'REQUEST'">
                      Thank you for submitting your request. Waiting for an
                      <b>amount</b> to be assigned by provider.
                    </span>
                    <span v-if="daas.status == 'PAID'">
                      Thank you for making your payment. Waiting for your
                      dispatch to be <b>approved</b> by provider.
                    </span>
                    <span v-if="daas.status == 'DISPATCH'">
                      Your dispatch has been approved. The Rider with details
                      below, will pickup your dispatch. <br><br>
                      <h6>Rider Details:</h6>
                      <b>Name:</b> {{ daas.rider.name }}<br>
                      <b>Number Plate:</b> {{ daas.rider.plate_number }}<br>
                      <b>Phone:</b> {{ daas.rider.phone }}<br>
                    </span>
                    <span v-if="daas.status == 'COLLECTED'">
                      Your dispatch has been collected.<br>
                      You will receive a notification upon delivery.
                    </span>
                    <span v-if="daas.status == 'DELIVERED'">
                      Your dispatch has been delivered.<br>
                      Thank you for using our service.
                    </span>
                  </div>
                </div>
              </b-col>

              <b-col cols="12">
                <b-row>
                  <!--INVOICE-->
                  <b-col
                    md="12"
                    sm="12"
                    class="text-center"
                  >
                    <b-card>
                      <h2>INVOICE</h2>
                      <div class="text-left">
                        <b-row>
                          <b-col cols="12">
                            <hr>
                          </b-col>

                          <!--Addresses-->
                          <b-col cols="12">
                            <b-row>
                              <b-col
                                md="5"
                                sm="12"
                              >
                                <h6>From:</h6>
                                <p>
                                  {{ daas.partner.name }}<br>
                                  {{ daas.partner.address }}<br>
                                  {{ daas.partner.email }}<br>
                                  {{ daas.partner.phone }}<br>
                                </p>
                              </b-col>
                              <b-col
                                md="5"
                                sm="12"
                                offset-md="2"
                              >
                                <h6>To/Pickup:</h6>
                                <p>
                                  {{ daas.params.name }}<br>
                                  {{ daas.dispatch_object.pickup.address
                                  }}<br>
                                  {{ daas.params.email }}<br>
                                  {{ daas.params.phone }}<br>
                                </p>
                              </b-col>
                            </b-row>
                          </b-col>

                          <b-col
                            cols="12"
                            class="mt-2"
                          >
                            <b-row>
                              <b-col
                                md="5"
                                sm="12"
                              >
                                <p>
                                  <b>Reference: </b> {{ transaction.reference }}
                                </p>
                              </b-col>
                              <b-col
                                md="5"
                                offset-md="2"
                                sm="12"
                              >
                                <p>
                                  <b>Date: </b>
                                  {{
                                    new Date(
                                      daas.created_at
                                    ).toLocaleTimeString("en-us", {
                                      weekday: "short",
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    })
                                  }}
                                </p>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col
                                cols="12"
                                class="mt-3"
                              >
                                <b-table
                                  responsive="sm"
                                  :items="daas.dispatch_object.dropoffs"
                                  :fields="fields"
                                >
                                  <template #cell(index)="data">
                                    #{{ data.index + 1 }}
                                  </template>
                                  <template #cell(dropoffs)="data">
                                    <feather-icon
                                      icon="MapPinIcon"
                                      class="text-danger"
                                    />
                                    {{ data.item.address }}
                                  </template>
                                  <template #cell(contact)="data">
                                    <feather-icon
                                      icon="UserIcon"
                                      class="text-success"
                                    />
                                    {{ data.item.receiver_name }}<br>
                                    <feather-icon
                                      icon="SmartphoneIcon"
                                      class="text-info"
                                    />
                                    {{ data.item.receiver_phone }}<br>
                                    <!-- <feather-icon
                                    icon="MailIcon"
                                    class="text-warning"
                                  />
                                  {{ data.item.receiver_email }}<br /> -->
                                  </template>
                                  <template #cell(packages)="data">
                                    <div v-for="pkg in data.item.packages">
                                      <feather-icon
                                        icon="PackageIcon"
                                        class="text-primary"
                                      />
                                      {{ pkg.name }}
                                    </div>
                                  </template>
                                </b-table>
                              </b-col>
                            </b-row>
                          </b-col>

                          <b-col cols="12">
                            <hr>
                          </b-col>

                          <b-col
                            cols="12"
                            class="mt-3"
                          >
                            <b-row>
                              <b-col
                                md="3"
                                sm="12"
                              >
                                <b-form-group
                                  v-if="daas.status == 'PAYMENT'"
                                  label="Select Payment Method:"
                                  label-for="item-name"
                                >
                                  <b-form-select
                                    v-model="daas.payment_method"
                                    :options="payment_options"
                                  />
                                </b-form-group>
                                <b-form-group
                                  v-else-if="
                                    daas.status !== 'PAYMENT' &&
                                      daas.status != 'REQUEST'
                                  "
                                  label="Payment Method:"
                                  label-for="item-name"
                                >
                                  <b-form-input
                                    v-model="daas.payment_method"
                                    plaintext
                                  />
                                </b-form-group>
                                <b-form-group
                                  label="Current Status: "
                                  label-for="status"
                                >
                                  <b-form-input
                                    v-model="daas.status"
                                    plaintext
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col
                                md="6"
                                offset-md="3"
                                sm="12"
                              >
                                <b-table
                                  responsive="sm"
                                  :items="items2"
                                  :fields="fields2"
                                >
                                  <template #cell(value)="data">
                                    <h4 v-if="daas.amount && daas.amount > 0">
                                      {{ daas.amount ? "N" : ""
                                      }}{{ daas.amount }}
                                    </h4>
                                  </template>
                                </b-table>
                              </b-col>
                            </b-row>
                          </b-col>

                          <b-col
                            cols="12"
                            class="mt-0"
                          >
                            <!--Note-->
                            <b-row>
                              <b-col cols="12">
                                <h6>Note:</h6>
                                <p>
                                  If you have any questions about this invoice,
                                  simply call or reach out to this provider for
                                  help, using details provided above.
                                </p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </div>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>

              <!--SUBMIT BUTTON-->
              <b-col
                cols="12"
                class="mt-2"
              >
                <paystack
                  v-if="
                    daas.status == 'PAYMENT' && daas.payment_method == 'CARD'
                  "
                  :amount="daas.amount * 100"
                  :email="daas.params.email ? daas.params.email : daas.partner.email"
                  paystackkey="pk_test_45f44c172822c5836e89a231e53736e19eb7f74e"
                  :reference="reference"
                  :metadata="daas"
                  :callback="processPayment"
                  :close="close"
                  :embed="false"
                  class="mb-1 btn-sm-block btn-lg btn-primary"
                >
                  PAY WITH CARD
                </paystack>

                <b-button
                  v-if="
                    daas.status == 'PAYMENT' && daas.payment_method != 'CARD'
                  "
                  variant="primary"
                  class="mb-1 btn-sm-block btn-lg"
                  @click="confirmPay"
                >
                  PAY WITH
                  {{ daas.payment_method == "CASH" ? "CASH" : "WALLET" }}
                </b-button>

                <!-- <form id="paymentForm">
              <b-button
                variant="danger"
                class="mb-1 btn-sm-block btn-lg mr-2"
                @click="confirmText"
              >
                CANCEL
              </b-button>
              <b-button
                variant="primary"
                class="mb-1 btn-sm-block btn-lg"
                @click="confirmPay"
                v-if="daas.status == 'PAYMENT'"
              >
                PAY
              </b-button>

              <input
                type="hidden"
                id="email-address"
                :value="paystackEmail"
                required
              />
              <input type="hidden" id="amount" :value="daas.amount" required />
              <input
                type="text"
                id="full_name"
                :value="daas.params.name"
                required
              /> -->
                <!-- <input type="hidden" id="first-name" :value="firstName" />
              <input type="hidden" id="last-name" :value="lastName" />
              <button type="submit" onclick="payWithPaystack()">Pay</button> -->
                <!-- </form> -->
              </b-col>
            </b-row>
          </div>
          <!-- </b-form> -->
          <!-- </b-card> -->
        </div>
      </b-overlay>
    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<!-- <script src="https://js.paystack.co/v1/inline.js"></script> -->
<script>
/* eslint-disable global-require */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BLink,
  BForm,
  BFormInput,
  BButton,
  BImg,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BAvatar,
  BTable,
  BFormSelect,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { apiUrl } from '@/constants/config'
import store from '@/store/index'
import axios from 'axios'
import paystack from 'vue-paystack'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BCard,
    /* BImg,
        BCardBody, */
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BAvatar,
    BTable,
    BFormSelect,
    BCardCode,
    paystack,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/coming-soon.svg'),
      fields: [
        { key: 'index', label: 'SN' },
        { key: 'dropoffs', label: 'dropoff', sortable: false },
        { key: 'contact', label: 'contact', sortable: false },
        { key: 'packages', label: 'packages', sortable: false },
      ],
      /* items: [
              {
                sn: "#1",
                dropoffs: {
                  address: "Gudu District, Abuja, FCT",
                  longitude: 3.22,
                  latitude: 4.32,
                },
                contact: {
                  name: "Ibukun Ogundare",
                  phone: "08102324343",
                  email: "ayomikunjude@gmail.com",
                },
                packages: [{ name: "Television" }, { name: "Home Theatre" }],
              },
              {
                sn: "#2",
                dropoffs: {
                  address: "Abacha Estate, Mararaba, Nassarawa",
                  longitude: 3.22,
                  latitude: 4.32,
                },
                contact: {
                  name: "Mubarak Oasdfas",
                  phone: "09098323423",
                },
                packages: [{ name: "HP 15 Laptop" }],
              },
            ], */
      fields2: [
        { key: 'title', label: '' },
        { key: 'value', label: 'Summary' },
      ],
      items2: [
        { title: 'Total', value: '5,400' },
        { title: '', value: '' },
      ],
      isNewCustomer: true,
      customer: {
        phone: '',
        name: '',
        email: '',
        id: '',
      },
      payment_options: [
        { value: 'CARD', text: 'CARD' },
        { value: 'CASH', text: 'CASH' },
        // { value: "WALLET", text: "WALLET" },
      ],
      daas: {
        params: {
          name: '',
          phone: '',
          email: '',
        },
        id: '',
        dispatch_object: {
          pickup: {
            address: '',
            longitude: '',
            latitude: '',
          },
          dropoffs: [
            {
              address: '',
              longitude: '',
              latitude: '',
              receiver_name: '',
              receiver_phone: '',
              packages: [{ id: 1, name: '' }],
              id: 1,
              nextPackageId: 1,
            },
          ],
          duration: '60',
          scheduled_for: null,
        },
        partner: {
          name: '',
          email: '',
          phone: '',
          address: '',
        },
        amount: '',
        status: '',
        payment_method: 'CARD',
        rider: {
          id: '',
          name: '',
          email: '',
          phone: '',
          plate_number: '',
        },
      },
      showOverlay: false,
      transaction: {
        status: '',
        reference: '',
        message: '',
      },
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    paystackEmail() {
      return this.daas.params.email ?? this.daas.partner.email
    },
    reference() {
      let text = ''

      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

      for (let i = 0; i < 10; i++) { text += possible.charAt(Math.floor(Math.random() * possible.length)) }
      // console.log('BXT-'+(new Date).getFullYear()+'-'+text)
      this.transaction.reference = `BXT${new Date().getFullYear()}${text}`
      return `BXT${new Date().getFullYear()}${text}`
    },
  },

  mounted() {
    // this.loadPaystack();
  },
  created() {
    this.getDaas()
  },
  destroyed() { },
  methods: {
    // confirm text
    confirmText() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to return to this dispatch request, after you cancel!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          setTimeout(() => {
            this.$router.replace({ path: '/cancelled' })
          }, 500)
          /* this.$swal({
                      icon: "success",
                      title: "Cancelled!",
                      text: "Your dispatch request has been cancelled.",
                      customClass: {
                        confirmButton: "btn btn-success",
                      },
                    }); */
        }
      })
    },
    confirmPay() {
      this.$swal({
        title: 'Are you sure?',
        // text: "You will be redirected to a payment gateway!",
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed to pay!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateDaas()
          /* this.$swal({
                      icon: "success",
                      title: "PAYSTACK!",
                      text: "Payment successful.",
                      customClass: {
                        confirmButton: "btn btn-success",
                      },
                    }); */
          // payWithPaystack();
        }
      })
    },

    getDaas() {
      this.show = true
      try {
        axios
          .get(`${apiUrl}daas/${this.$route.params.ref}`, {
            headers: {
              'content-type': 'application/json',
            },
          })
          .then(response => {
            // console.log(response.data.data.data);
            const daas2 = response.data.data
            this.daas = daas2
            /* this.daas.params.name = daas2.params.name ?? this.customer.name;
                        this.daas.params.phone = daas2.params.phone ?? this.customer.phone;
                        this.daas.params.email = daas2.params.email ?? this.customer.email; */
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.show = false
          })
      } catch (e) {
        console.log(e)
      }
    },

    updateDaas() {
      this.showOverlay = true
      // post data
      axios({
        url: `${apiUrl}daas/${this.daas.id}`,
        data: {
          status: 'PAID',
          payment_method: this.daas.payment_method,
          payload: this.daas.payment_method == 'CARD' ? this.transaction : null,
          reference: this.transaction.reference,
        },
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
        },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success: ${response.data.message}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            /* this.$router.replace({
                          path: "/" + this.$route.params.ref,
                        }); */
            window.location.href = `/invoice/${this.$route.params.ref}`
          }, 500)
        })
        .catch(error => {
          this.error_head = `Error: ${error.response.statusText}` ?? ''
          this.error_body = error.response.data.message
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error: ${error.response.statusText}` ?? error.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showOverlay = false
        })
    },

    // Paystack
    processPayment(transaction) {
      this.transaction = transaction

      // setTimeout(() => {
      if (
        this.transaction.status == 'success'
                && this.transaction.message == 'Approved'
      ) {
        this.updateDaas()
      }
      // }, 500);
    },

    close() {
      console.log('You closed checkout page')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
